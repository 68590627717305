/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

const countriesCodeWithFlags: [string, string][] = [
    ['aa', '🇪🇹'], // Afar - Ethiopia
    ['ab', '🇬🇪'], // Abkhaz - Georgia
    ['af', '🇿🇦'], // Afrikaans - South Africa
    ['am', '🇪🇹'], // Amharic - Ethiopia
    ['ar', '🇪🇬'], // Arabic - Egypt
    ['as', '🇮🇳'], // Assamese - India
    ['ay', '🇧🇴'], // Aymara - Bolivia
    ['az', '🇦🇿'], // Azerbaijani - Azerbaijan
    ['be', '🇧🇾'], // Belarusian - Belarus
    ['bg', '🇧🇬'], // Bulgarian - Bulgaria
    ['bi', '🇻🇺'], // Bislama - Vanuatu
    ['bn', '🇧🇩'], // Bengali - Bangladesh
    ['bh', '🇧🇭'], // Bahrain - Bahrain
    ['bo', '🇨🇳'], // Tibetan - China
    ['br', '🇫🇷'], // Breton - France
    ['bs', '🇧🇦'], // Bosnian - Bosnia and Herzegovina
    ['ca', '🇪🇸'], // Catalan - Spain
    ['co', '🇫🇷'], // Corsican - France
    ['cs', '🇨🇿'], // Czech - Czechia
    ['cy', '🏴󠁧󠁢󠁷󠁬󠁳󠁿'], // Welsh - Wales
    ['da', '🇩🇰'], // Danish - Denmark
    ['de', '🇩🇪'], // German - Germany
    ['dv', '🇲🇻'], // Divehi - Maldives
    ['dz', '🇧🇹'], // Dzongkha - Bhutan
    ['el', '🇬🇷'], // Greek - Greece
    ['en', '🇬🇧'], // English - United Kingdom
    ['eo', '🌐'], // Esperanto - No associated country
    ['es', '🇪🇸'], // Spanish - Spain
    ['et', '🇪🇪'], // Estonian - Estonia
    ['eu', '🇪🇸'], // Basque - Spain
    ['fa', '🇮🇷'], // Persian - Iran
    ['fi', '🇫🇮'], // Finnish - Finland
    ['fj', '🇫🇯'], // Fijian - Fiji
    ['fo', '🇫🇴'], // Faroese - Faroe Islands
    ['fr', '🇫🇷'], // French - France
    ['fy', '🇳🇱'], // Western Frisian - Netherlands
    ['ga', '🇮🇪'], // Irish - Ireland
    ['gd', '🏴󠁧󠁢󠁳󠁣󠁴󠁿'], // Scottish Gaelic - United Kingdom
    ['gl', '🇪🇸'], // Galician - Spain
    ['gn', '🇵🇾'], // Guarani - Paraguay
    ['gu', '🇮🇳'], // Gujarati - India
    ['ha', '🇳🇬'], // Hausa - Nigeria
    ['he', '🇮🇱'], // Hebrew - Israel
    ['hi', '🇮🇳'], // Hindi - India
    ['hr', '🇭🇷'], // Croatian - Croatia
    ['hu', '🇭🇺'], // Hungarian - Hungary
    ['hy', '🇦🇲'], // Armenian - Armenia
    ['ia', '🌐'], // Interlingua - No associated country
    ['id', '🇮🇩'], // Indonesian - Indonesia
    ['ie', '🌐'], // Interlingue - No associated country
    ['ik', '🇺🇸'], // Inupiaq - United States (Alaska)
    ['is', '🇮🇸'], // Icelandic - Iceland
    ['it', '🇮🇹'], // Italian - Italy
    ['iu', '🇨🇦'], // Inuktitut - Canada
    ['ja', '🇯🇵'], // Japanese - Japan
    ['jv', '🇮🇩'], // Javanese - Indonesia
    ['ka', '🇬🇪'], // Georgian - Georgia
    ['kk', '🇰🇿'], // Kazakh - Kazakhstan
    ['kl', '🇬🇱'], // Kalaallisut - Greenland
    ['km', '🇰🇭'], // Khmer - Cambodia
    ['kn', '🇮🇳'], // Kannada - India
    ['ko', '🇰🇷'], // Korean - South Korea
    ['ks', '🇮🇳'], // Kashmiri - India
    ['ku', '🇹🇷'], // Kurdish - Turkey
    ['ky', '🇰🇬'], // Kyrgyz - Kyrgyzstan
    ['la', '🇻🇦'], // Latin - Vatican City
    ['lb', '🇱🇺'], // Luxembourgish - Luxembourg
    ['ln', '🇨🇩'], // Lingala - Democratic Republic of the Congo
    ['lo', '🇱🇦'], // Lao - Laos
    ['lt', '🇱🇹'], // Lithuanian - Lithuania
    ['lv', '🇱🇻'], // Latvian - Latvia
    ['mg', '🇲🇬'], // Malagasy - Madagascar
    ['mi', '🇳🇿'], // Māori - New Zealand
    ['mk', '🇲🇰'], // Macedonian - North Macedonia
    ['ml', '🇮🇳'], // Malayalam - India
    ['mn', '🇲🇳'], // Mongolian - Mongolia
    ['mr', '🇮🇳'], // Marathi - India
    ['ms', '🇲🇾'], // Malay - Malaysia
    ['mt', '🇲🇹'], // Maltese - Malta
    ['my', '🇲🇲'], // Burmese - Myanmar
    ['na', '🇳🇷'], // Nauru - Nauru
    ['ne', '🇳🇵'], // Nepali - Nepal
    ['nl', '🇳🇱'], // Dutch - Netherlands
    ['no', '🇳🇴'], // Norwegian - Norway
    ['oc', '🇫🇷'], // Occitan - France
    ['om', '🇪🇹'], // Oromo - Ethiopia
    ['or', '🇮🇳'], // Odia - India
    ['pa', '🇮🇳'], // Punjabi - India
    ['pl', '🇵🇱'], // Polish - Poland
    ['ps', '🇦🇫'], // Pashto - Afghanistan
    ['pt', '🇵🇹'], // Portuguese - Portugal
    ['qu', '🇵🇪'], // Quechua - Peru
    ['rm', '🇨🇭'], // Romansh - Switzerland
    ['rn', '🇧🇮'], // Rundi - Burundi
    ['ro', '🇷🇴'], // Romanian - Romania
    ['ru', '🇷🇺'], // Russian - Russia
    ['rw', '🇷🇼'], // Kinyarwanda - Rwanda
    ['sa', '🇮🇳'], // Sanskrit - India
    ['sd', '🇵🇰'], // Sindhi - Pakistan
    ['sg', '🇨🇫'], // Sango - Central African Republic
    ['si', '🇱🇰'], // Sinhala - Sri Lanka
    ['sk', '🇸🇰'], // Slovak - Slovakia
    ['sl', '🇸🇮'], // Slovene - Slovenia
    ['sm', '🇼🇸'], // Samoan - Samoa
    ['sn', '🇿🇼'], // Shona - Zimbabwe
    ['so', '🇸🇴'], // Somali - Somalia
    ['sq', '🇦🇱'], // Albanian - Albania
    ['sr', '🇷🇸'], // Serbian - Serbia
    ['ss', '🇸🇿'], // Swati - Eswatini
    ['st', '🇱🇸'], // Southern Sotho - Lesotho
    ['su', '🇮🇩'], // Sundanese - Indonesia
    ['sv', '🇸🇪'], // Swedish - Sweden
    ['sw', '🇹🇿'], // Swahili - Tanzania
    ['ta', '🇮🇳'], // Tamil - India
    ['te', '🇮🇳'], // Telugu - India
    ['tg', '🇹🇯'], // Tajik - Tajikistan
    ['th', '🇹🇭'], // Thai - Thailand
    ['ti', '🇪🇹'], // Tigrinya - Ethiopia
    ['tk', '🇹🇲'], // Turkmen - Turkmenistan
    ['tl', '🇵🇭'], // Tagalog - Philippines
    ['tn', '🇧🇼'], // Tswana - Botswana
    ['to', '🇹🇴'], // Tongan - Tonga
    ['tr', '🇹🇷'], // Turkish - Turkey
    ['ts', '🇿🇦'], // Tsonga - South Africa
    ['tt', '🇷🇺'], // Tatar - Russia
    ['tw', '🇬🇭'], // Twi - Ghana
    ['ty', '🇵🇫'], // Tahitian - French Polynesia
    ['ug', '🇨🇳'], // Uyghur - China
    ['uk', '🇺🇦'], // Ukrainian - Ukraine
    ['ur', '🇵🇰'], // Urdu - Pakistan
    ['uz', '🇺🇿'], // Uzbek - Uzbekistan
    ['ve', '🇿🇦'], // Venda - South Africa
    ['vi', '🇻🇳'], // Vietnamese - Vietnam
    ['vo', '🌐'], // Volapük - No associated country
    ['wa', '🇧🇪'], // Walloon - Belgium
    ['wo', '🇸🇳'], // Wolof - Senegal
    ['xh', '🇿🇦'], // Xhosa - South Africa
    ['yi', '🇮🇱'], // Yiddish - Israel
    ['yo', '🇳🇬'], // Yoruba - Nigeria
    ['za', '🇨🇳'], // Zhuang - China
    ['zh', '🇨🇳'], // Chinese - China
    ['zu', '🇿🇦'], // Zulu - South Africa
];

const codeLanguageToFlag = (countryCode: string) => {
    const countriesMap = new Map(countriesCodeWithFlags);
    if (countriesMap.has(countryCode.toLocaleLowerCase())) {
        return `${countriesMap.get(countryCode.toLowerCase())} ${countryCode}`;
    }

    return countryCode === '*' ? '🌐 joker' : `🌐 ${countryCode}`;
};

export default codeLanguageToFlag;
