/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import { Edit, useNotify, useRedirect, useTranslate, useUpdate } from 'react-admin';
import EventForm from '../../components/form/EventForm';
import PageTitle from '../../components/PageTitle';
import { EventFormPayload, EventType } from '../../entities/Event';

const EditEvent = () => {
    const translate = useTranslate();
    const [update] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();

    const handleSubmit = async (payload: EventFormPayload) => {
        if (
            !payload.id ||
            !payload.title ||
            !payload.content ||
            !payload.startDate ||
            !payload.endDate ||
            payload.diffusionLanguages?.length === 0 ||
            payload.concernedUniversities?.length === 0 ||
            (payload.type === EventType.ONLINE && !payload.eventURL) ||
            (payload.type === EventType.PRESENTIAL && (!payload.address || !payload.addressName))
        ) {
            return notify('events.form.error.required', {
                type: 'error',
            });
        }

        const formData = new FormData();

        formData.append('title', payload.title);
        formData.append('content', payload.content);
        formData.append('languageCode', payload.languageCode);
        formData.append('withSubscription', payload.withSubscription.toString());
        formData.append('status', payload.status);
        formData.append('type', payload.type);
        formData.append('startDate', payload.startDate.toISOString());
        formData.append('endDate', payload.endDate.toISOString());

        payload.translations.forEach((translation, index) => {
            formData.append(`translations[${index}][title]`, translation.title);
            formData.append(`translations[${index}][content]`, translation.content);
            formData.append(`translations[${index}][languageCode]`, translation.languageCode);
        });

        payload.diffusionLanguages.forEach((language, index) => {
            formData.append(`diffusionLanguages[${index}]`, language);
        });

        payload.concernedUniversities?.forEach((university, index) => {
            formData.append(`concernedUniversities[${index}]`, university.id);
        });

        if (payload.eventURL) formData.append('eventUrl', payload.eventURL);
        if (payload.address) formData.append('address', payload.address);
        if (payload.addressName) formData.append('addressName', payload.addressName);
        if (payload.image) formData.append('file', payload.image);
        if (payload.imageCredit) formData.append('imageCredit', payload.imageCredit);

        try {
            return await update(
                'events',
                { id: payload.id, data: formData },
                {
                    onSettled: (_, error: any) => {
                        if (!error) {
                            return redirect('/events');
                        }

                        return notify('events.update.error', {
                            type: 'error',
                        });
                    },
                }
            );
        } catch (err) {
            console.error(err);

            return notify('events.update.error', {
                type: 'error',
            });
        }
    };

    return (
        <>
            <PageTitle>{translate('events.title')}</PageTitle>
            <Edit>
                <EventForm handleSubmit={handleSubmit} />
            </Edit>
        </>
    );
};

export default EditEvent;
