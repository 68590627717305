/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import { Typography, Box, OutlinedInput } from '@mui/material';
import React from 'react';
import { Button, useTranslate } from 'react-admin';
import IndexedTranslation from '../../entities/IndexedTranslation';
import Translation from '../../entities/Translation';
import TranslationLanguagePicker from '../TranslationLanguagePicker';

interface TranslationFormProps {
    setTranslations: (translations: IndexedTranslation[]) => void;
    translations: IndexedTranslation[];
}

const TranslationForm: React.FC<TranslationFormProps> = ({ setTranslations, translations }) => {
    const translate = useTranslate();

    const onTraductionLanguageAdded = (value: TranslatedLanguage, index: number) => {
        const currentTraductions = [...translations];
        currentTraductions[index].translation.language = value;
        setTranslations(currentTraductions);
    };

    const onTraductionContentAdded = (value: string, index: number) => {
        const currentTraductions = [...translations];
        currentTraductions[index].translation.content = value;
        setTranslations(currentTraductions);
    };

    return (
        <Box>
            <Typography variant="subtitle1">{translate('global.translations')}</Typography>
            {translations.map((item, index) => (
                <Box
                    key={item.index}
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    gap="20px"
                    marginBottom="20px"
                >
                    <TranslationLanguagePicker
                        onChange={(value: TranslatedLanguage) => onTraductionLanguageAdded(value, index)}
                        value={translations[index].translation.language}
                    />
                    <OutlinedInput
                        name={`Content${item.index}`}
                        onChange={(e) => onTraductionContentAdded(e.target.value, index)}
                        placeholder={translate('global.content')}
                        value={translations[index].translation.content}
                    />
                </Box>
            ))}

            <Box alignContent="flex-start" display="flex" flexDirection="column" sx={{ width: 300 }}>
                <Button
                    onClick={() =>
                        setTranslations([
                            ...translations,
                            {
                                index: translations.length + 1,
                                translation: new Translation('', 'en'),
                            },
                        ])
                    }
                    type="button"
                    variant="outlined"
                >
                    <>{translate('global.new_translation')}</>
                </Button>
            </Box>
        </Box>
    );
};

export default TranslationForm;
