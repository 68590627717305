/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import React from 'react';
import { useTranslate, useNotify, useRedirect, useUpdate, WithRecord, Edit, usePermissions } from 'react-admin';
import ReportForm from '../../components/form/ReportForm';
import ReportsPagesHeader from '../../components/tabs/ReportsPagesHeader';
import { Role } from '../../entities/Administrator';
import Report, { ReportStatus } from '../../entities/Report';

const EditReport = () => {
    const translate = useTranslate();

    const { permissions } = usePermissions();
    const canEdit = permissions.checkRoles([Role.MANAGER, Role.SUPER_ADMIN]);
    if (!canEdit) {
        return <div>{translate('reports.error.unauthorized')}</div>;
    }

    const [update] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();

    const handleSubmit = async (id: string, status: ReportStatus, comment?: string, shouldDeleteMessage?: boolean) => {
        const payload = {
            status,
            comment,
            shouldDeleteMessage,
        };
        try {
            return await update(
                `reports/${id}`,
                { data: payload },
                {
                    onSettled: (_, error: unknown) => {
                        if (!error) {
                            return redirect('/reports');
                        }
                        console.warn(error);

                        return notify('reports.update.error');
                    },
                }
            );
        } catch (err) {
            console.error(err);

            return notify('reports.update.error');
        }
    };

    return (
        <>
            <ReportsPagesHeader />
            <Edit title={translate('reports.update.title')}>
                <WithRecord<Report>
                    render={(record) => (
                        <ReportForm
                            category={record.category.name}
                            comment={record.comment}
                            content={record.content}
                            handleSubmit={(status: ReportStatus, comment?: string, shouldDeleteMessage?: boolean) =>
                                handleSubmit(record.id, status, comment, shouldDeleteMessage)
                            }
                            isMessageDeleted={record.metadata?.isMessageDeleted}
                            messageId={record.metadata?.messageId}
                            status={record.status}
                            user={record.user}
                        />
                    )}
                />
            </Edit>
        </>
    );
};

export default EditReport;
