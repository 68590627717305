/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import EditIcon from '@mui/icons-material/Edit';
import PeopleIcon from '@mui/icons-material/People';
import { Box } from '@mui/material';
import React from 'react';
import {
    List,
    Datagrid,
    useTranslate,
    TextField,
    FunctionField,
    useGetIdentity,
    Loading,
    DateField,
    TextInput,
    SelectInput,
    Button,
    BulkDeleteButton,
    usePermissions,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import ColoredChips, { ChipsColors } from '../../components/ColoredChips';
import useGetUniversitiesLanguages from '../../components/form/useGetUniversitiesLanguages';
import PageTitle from '../../components/PageTitle';
import { Role } from '../../entities/Administrator';
import { EventObject, EventStatus, EventTranslation, EventType } from '../../entities/Event';
import codeLanguageToFlag from '../../utils/codeLanguageToFlag';

const StatusChips = ({ status }: { status: string }) => {
    const translate = useTranslate();

    let color: ChipsColors = 'default';
    switch (status) {
        case EventStatus.DRAFT:
            color = 'default';
            break;
        case EventStatus.READY:
            color = 'success';
            break;
    }

    return <ColoredChips color={color} label={translate(`events.status.${status}`)} />;
};

const EventsList = () => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const { permissions } = usePermissions();
    const { data: identity, isLoading: isLoadingIdentity } = useGetIdentity();
    const { universitiesLanguages, universitiesData } = useGetUniversitiesLanguages();

    if (isLoadingIdentity || !identity) {
        return <Loading />;
    }

    const filters = [
        <TextInput key="titleFilter" label={translate('events.list.filters.title')} source="title" alwaysOn />,
        <SelectInput
            key="defaultLanguageFilter"
            choices={universitiesLanguages.map((language) => ({
                id: language.code,
                name: codeLanguageToFlag(language.code),
            }))}
            label={translate('events.list.filters.language')}
            source="languageCode"
            alwaysOn
        />,
        <SelectInput
            key="typeFilter"
            choices={Object.values(EventType).map((type) => ({
                id: type,
                name: translate(`events.type.${type}`),
            }))}
            label={translate('events.list.filters.type')}
            source="type"
            alwaysOn
        />,
        <SelectInput
            key="statusFilter"
            choices={Object.values(EventStatus).map((status) => ({
                id: status,
                name: translate(`events.status.${status}`),
            }))}
            label={translate('events.list.filters.status')}
            source="status"
            alwaysOn
        />,
    ];

    if (identity?.isCentralUniversity && universitiesData) {
        filters.unshift(
            <SelectInput
                key="groupFilter"
                choices={universitiesData}
                label={translate('events.list.filters.university')}
                source="authorUniversityId"
                alwaysOn
            />
        );
    }

    return (
        <>
            <PageTitle>{translate('events.title')}</PageTitle>
            <List
                exporter={false}
                filter={
                    !identity?.isCentralUniversity || !permissions.checkRole(Role.SUPER_ADMIN)
                        ? { authorUniversityId: identity?.universityId }
                        : undefined
                }
                filters={filters}
                disableSyncWithLocation
            >
                <Datagrid bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}>
                    <TextField label="events.list.title" source="title" />
                    {identity?.isCentralUniversity && (
                        <TextField label="events.list.university" source="authorUniversity.name" />
                    )}

                    <FunctionField
                        label="events.list.defaultLanguage"
                        render={(record: EventObject) => codeLanguageToFlag(record.languageCode)}
                    />
                    <FunctionField
                        label="events.list.translations"
                        render={(record: EventObject) =>
                            record.translations
                                ?.map((translation: EventTranslation) => codeLanguageToFlag(translation.languageCode))
                                .join(', ')
                        }
                    />
                    <DateField label="events.list.startDate" source="startDate" />
                    <DateField label="events.list.endDate" source="endDate" />
                    <FunctionField
                        label="events.list.type"
                        render={(record: EventObject) => translate(`events.type.${record.type}`)}
                    />
                    <FunctionField
                        label="news.list.status"
                        render={(record: EventObject) => <StatusChips status={record.status} />}
                    />
                    <FunctionField
                        label="events.subscriptions.list.cta"
                        render={(record: EventObject) => (
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Button
                                    onClick={() => navigate(`/events/${record.id}`)}
                                    sx={{ '& span': { margin: 0 } }}
                                    variant="outlined"
                                >
                                    <EditIcon />
                                </Button>
                                {record.withSubscription && (
                                    <Button
                                        onClick={() => navigate(`/events/subscriptions/${record.id}`)}
                                        sx={{ '& span': { margin: 0 } }}
                                        variant="outlined"
                                    >
                                        <PeopleIcon />
                                    </Button>
                                )}
                            </Box>
                        )}
                    />
                </Datagrid>
            </List>
        </>
    );
};

export default EventsList;
