/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import React from 'react';
import {
    FunctionField,
    useTranslate,
    Filter,
    SelectInput,
    Datagrid,
    List,
    TextField,
    DateField,
    Loading,
    useGetIdentity,
    usePermissions,
} from 'react-admin';
import ReportsPagesHeader from '../../components/tabs/ReportsPagesHeader';
import { Role } from '../../entities/Administrator';

const ReportFilter = (props: any) => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <SelectInput
                choices={[
                    { id: 'OPEN', name: translate('reports.OPEN') },
                    { id: 'IN_PROGRESS', name: translate('reports.IN_PROGRESS') },
                    { id: 'CLOSED', name: translate('reports.CLOSED') },
                ]}
                label={translate('reports.status')}
                source="status"
            />
        </Filter>
    );
};

const ReportList = () => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const { data: identity, isLoading: isLoadingIdentity } = useGetIdentity();

    if (isLoadingIdentity || !identity) {
        return <Loading />;
    }

    const readOnly: boolean = permissions.checkRole(Role.ANIMATOR);

    return (
        <>
            <ReportsPagesHeader />
            <List
                exporter={false}
                filter={!identity?.isCentralUniversity ? { universityId: identity.universityId } : undefined}
                filters={<ReportFilter />}
            >
                <Datagrid bulkActionButtons={readOnly ? false : undefined} rowClick="show">
                    <TextField label={translate('reports.category')} source="category.name" />
                    <TextField label={translate('global.lastname')} source="user.lastname" />
                    <TextField label={translate('global.firstname')} source="user.firstname" />
                    <TextField label={translate('global.university')} source="user.university.name" />
                    <FunctionField
                        label={translate('reports.status')}
                        render={(record: any) => translate(`reports.${record.status}`)}
                    />
                    <TextField label={translate('reports.content')} sortable={false} source="content" />
                    <DateField label={translate('reports.created_at')} source="createdAt" />
                </Datagrid>
            </List>
        </>
    );
};

export default ReportList;
