/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Divider, ListItem, ListSubheader } from '@mui/material';
import * as React from 'react';
import {
    UserMenu,
    MenuItemLink,
    useTranslate,
    useLogout,
    useGetIdentity,
    useUserMenu,
    UserIdentity,
} from 'react-admin';
import CustomAvatar from '../CustomAvatar';

type UsernameProps = {
    user: UserIdentity;
};

type AdminMenuProps = {
    user: UserIdentity | undefined;
};

const Username = ({ user }: UsernameProps) => (
    <>
        <ListItem>{`${user.firstName} ${user.lastName}`}</ListItem>
        <ListSubheader sx={{ lineHeight: 'inherit', marginBottom: '12px' }}>{user.email}</ListSubheader>
    </>
);

const AdminMenu = ({ user }: AdminMenuProps) => {
    const translate = useTranslate();
    const logout = useLogout();
    const { onClose } = useUserMenu();

    return (
        <div>
            {user && <Username user={user} />}
            {user && <Divider />}
            {user && (
                <MenuItemLink
                    leftIcon={<PersonIcon />}
                    onClick={onClose}
                    primaryText={translate('global.profile')}
                    to={`/admin-profile/${user.id}`}
                />
            )}
            {user && <Divider />}
            <MenuItemLink
                leftIcon={<ExitToAppIcon />}
                onClick={logout}
                primaryText={translate('global.disconnect')}
                to="/"
            />
        </div>
    );
};

const CustomUserMenu = (props: any) => {
    const { data: user } = useGetIdentity();
    const avatar = user ? (
        <CustomAvatar avatarId={user.id} firstName={user.firstName} lastName={user.lastName} />
    ) : (
        <Avatar />
    );

    return (
        <UserMenu {...props} icon={avatar}>
            <AdminMenu user={user} />
        </UserMenu>
    );
};

export default CustomUserMenu;
