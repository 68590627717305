/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import CloseIcon from '@mui/icons-material/Close';
import { Box, OutlinedInput, Typography } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import { useState } from 'react';
import { Button, SimpleForm, useDataProvider, useNotify, useTranslate } from 'react-admin';

type SendEmailProps = {
    setIsModalOpen: (value: boolean) => void;
    eventId: string;
};

const SendEmail = ({ setIsModalOpen, eventId }: SendEmailProps) => {
    const translate = useTranslate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleSubmit = async () => {
        try {
            await dataProvider.sendEventUsersEmail(eventId, title, content);
            notify(translate('events.subscriptions.email.success'), { type: 'success' });
            setIsModalOpen(false);
        } catch (error) {
            notify(translate('events.subscriptions.email.error'), { type: 'error' });
        }
    };

    return (
        <Box sx={{ backgroundColor: 'white', padding: 5, margin: 10, borderRadius: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="h3">{translate('events.subscriptions.email.title')}</Typography>
                <Button onClick={() => setIsModalOpen(false)}>
                    <CloseIcon />
                </Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <Box sx={{ width: '100%' }}>
                    <Typography variant="subtitle1">{translate('events.subscriptions.email.form.title')}</Typography>
                    <OutlinedInput
                        name="Title"
                        onChange={(e: any) => setTitle(e.target.value)}
                        type="text"
                        value={title}
                        fullWidth
                        required
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        '& .RaLabeled-label': { display: 'none' },
                        '& .MuiToolbar-root': { display: 'none' },
                    }}
                >
                    <Typography variant="subtitle1">{translate('events.subscriptions.email.form.content')}</Typography>
                    <SimpleForm sx={{ padding: 0 }}>
                        <RichTextInput
                            defaultValue={content}
                            onChange={(e: any) => setContent(e)}
                            source=""
                            fullWidth
                        />
                    </SimpleForm>
                </Box>
                <Button disabled={!title || !content} onClick={handleSubmit} variant="contained">
                    <span>{translate('events.subscriptions.email.send')}</span>
                </Button>
            </Box>
        </Box>
    );
};

export default SendEmail;
