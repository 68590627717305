/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';
import { useDataProvider, useRefresh, useTranslate } from 'react-admin';

type SelectedLearningLanguageActionProps = {
    selectedLearningLanguages: string[];
    setSelectedLearningLanguages: (learningLanguages: string[]) => void;
};

const SelectedLearningLanguageAction = ({
    selectedLearningLanguages,
    setSelectedLearningLanguages,
}: SelectedLearningLanguageActionProps) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const [open, setOpen] = useState(false);

    const handleDeleteLearningLanguages = async () => {
        await dataProvider.deleteMany('learning-languages', { ids: selectedLearningLanguages });
        setSelectedLearningLanguages([]);
        refresh();
        setOpen(false);
    };

    return (
        <>
            <Box
                sx={{
                    margin: '10px',
                    backgroundColor: '#fdee66',
                    width: 'fit-content',
                    padding: '15px 20px',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                <Typography>
                    {selectedLearningLanguages.length} {translate('learning_languages.list.checkboxAction.label')}
                </Typography>
                <Button color="primary" onClick={() => setOpen(true)} variant="contained">
                    {translate('learning_languages.list.checkboxAction.delete')}
                </Button>
            </Box>
            <Dialog open={open}>
                <DialogTitle>{translate('learning_languages.list.checkboxAction.dialog.title')}</DialogTitle>
                <DialogContent>{translate('learning_languages.list.checkboxAction.dialog.message')}</DialogContent>
                <DialogActions>
                    <Button color="success" onClick={handleDeleteLearningLanguages} variant="contained">
                        {translate('learning_languages.list.checkboxAction.dialog.ctaLabels.confirm')}
                    </Button>
                    <Button color="error" onClick={() => setOpen(false)} variant="contained">
                        {translate('learning_languages.list.checkboxAction.dialog.ctaLabels.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SelectedLearningLanguageAction;
