/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import { Box, Typography, OutlinedInput, FormControlLabel, FormGroup, Switch } from '@mui/material';
import React, { useState } from 'react';
import { Button, useTranslate } from 'react-admin';
import { ReportStatus } from '../../entities/Report';
import User from '../../entities/User';
import ReportStatusPicker from '../ReportStatusPicker';

interface ReportProps {
    handleSubmit: (status: ReportStatus, comment?: string, shouldDeleteMessage?: boolean) => void;
    category: string;
    content: string;
    status: ReportStatus;
    user: User;
    comment?: string;
    messageId?: string;
    isMessageDeleted?: boolean;
}

const ReportForm: React.FC<ReportProps> = ({
    handleSubmit,
    category,
    content,
    status,
    user,
    comment,
    messageId,
    isMessageDeleted,
}) => {
    const translate = useTranslate();
    const [newStatus, setNewStatus] = useState<ReportStatus>(status);
    const [newComment, setNewComment] = useState<string | undefined>(comment);
    const [shouldDeleteMessage, setShouldDeleteMessage] = useState<boolean>(Boolean(isMessageDeleted));

    return (
        <Box sx={{ margin: '32px', display: 'flex', flexDirection: 'column', gap: 2 }}>
            {messageId && (
                <Box>
                    <Typography variant="subtitle1">{translate('reports.update.deletion_status')}</Typography>
                    <FormGroup>
                        <FormControlLabel
                            checked={shouldDeleteMessage}
                            control={<Switch onChange={(event: any) => setShouldDeleteMessage(event.target.checked)} />}
                            label={translate(`reports.delete_message`)}
                            value={shouldDeleteMessage}
                        />
                    </FormGroup>
                </Box>
            )}
            <Box>
                <Typography variant="subtitle1">{translate(`global.firstname`)}</Typography>
                <Typography variant="subtitle2">
                    {user?.firstname ? user.firstname : translate('global.deleted_user')}
                </Typography>
            </Box>
            <Box>
                <Typography variant="subtitle1">{translate(`global.lastname`)}</Typography>
                <Typography variant="subtitle2">
                    {user?.lastname ? user.lastname : translate('global.deleted_user')}
                </Typography>
            </Box>
            <Box>
                <Typography variant="subtitle1">{translate(`global.email`)}</Typography>
                <Typography variant="subtitle2">
                    {user?.email ? user.email : translate('global.deleted_user')}
                </Typography>
            </Box>
            <Box>
                <Typography variant="subtitle1">{translate(`reports.update.status`)}</Typography>
                <ReportStatusPicker onChange={setNewStatus} value={newStatus} />
            </Box>
            <Box>
                <Typography variant="subtitle1">{translate(`reports.category`)}</Typography>
                <Typography variant="subtitle2">{category}</Typography>
            </Box>
            <Box>
                <Typography variant="subtitle1">{translate(`global.content`)}</Typography>
                <Typography variant="subtitle2">{content}</Typography>
            </Box>

            <Box>
                <Typography variant="subtitle1">{translate(`reports.update.comment`)}</Typography>
                <OutlinedInput
                    name="Content"
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder={translate('global.content')}
                    value={newComment}
                    multiline
                    required
                />
            </Box>

            <Button
                color="primary"
                onClick={() => handleSubmit(newStatus, newComment, shouldDeleteMessage)}
                sx={{ mt: 4, width: '100%' }}
                type="button"
                variant="contained"
            >
                <span>{translate('global.save')}</span>
            </Button>
        </Box>
    );
};

export default ReportForm;
