/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import Campus from './Campus';
import MediaObject from './MediaObject';
// eslint-disable-next-line import/no-cycle
import { Profile } from './Profile';
// eslint-disable-next-line import/no-cycle
import { Tandem, TandemStatus, TandemWithPartnerLearningLanguage } from './Tandem';

export enum LearningType {
    ETANDEM = 'ETANDEM',
    TANDEM = 'TANDEM',
    BOTH = 'BOTH',
}

export type LearningLanguage = {
    id: string;
    code: string;
    level: string;
    name: string;
    createdAt: Date;
    profile: Profile;
    tandem?: Tandem;
    sameGender: boolean;
    learningType: LearningType;
    sameAge: boolean;
    campus?: Campus;
    certificateOption?: boolean;
    specificProgram?: boolean;
    hasPriority?: boolean;
    learningJournal?: boolean;
    consultingInterview?: boolean;
    sharedCertificate?: boolean;
    certificateFile?: MediaObject;
    sameTandemEmail?: string;
    sharedLogsDate?: Date;
    sharedLogsForResearchDate?: Date;
};

export type LearningLanguageWithTandemWithPartnerProfile = {
    id: string;
    code: string;
    level: string;
    name: string;
    createdAt: Date;
    profile: Profile;
    tandem?: TandemWithPartnerLearningLanguage;
    sameGender: boolean;
    learningType: LearningType;
    sameAge: boolean;
    campus?: Campus;
    certificateOption?: boolean;
    specificProgram?: boolean;
    hasPriority?: boolean;
    sameTandemEmail?: string;
};

export type LearningLanguageTandem = {
    id: string;
    status: TandemStatus;
    userLearningLanguage: LearningLanguage;
    partnerLearningLanguage: LearningLanguage;
    universityValidations: string[];
    compatibilityScore: number;
};

export type CertificateFormPayload = {
    learningJournal?: boolean;
    consultingInterview?: boolean;
    sharedCertificate?: boolean;
};

/**
 * Return true if an action is possible on a learning language
 * Action possible = a tandem is actionable (validate, refuse, etc)
 * @param learningLanguage
 * @returns
 */
export const learningLanguageHasPossibleAction = (learningLanguage?: LearningLanguage) =>
    learningLanguage?.tandem?.status &&
    learningLanguage?.tandem?.status !== TandemStatus.ACTIVE &&
    learningLanguage?.tandem?.status !== TandemStatus.PAUSED &&
    learningLanguage?.tandem?.status !== TandemStatus.INACTIVE;

export const getLearningLanguageUniversityAndCampusString = (learningLanguage?: LearningLanguage) => {
    if (!learningLanguage) {
        return '';
    }
    if (learningLanguage.campus) {
        return `${learningLanguage.profile.user.university.name} - ${learningLanguage.campus.name}`;
    }

    return `${learningLanguage.profile.user.university.name}`;
};

export const isJoker = (
    learningLanguage?: LearningLanguage | LearningLanguageWithTandemWithPartnerProfile
): boolean => {
    if (learningLanguage?.code === '*') {
        return true;
    }

    return false;
};

export const getEffectiveLearningType = (
    learningLanguage1: LearningLanguage | LearningLanguageWithTandemWithPartnerProfile,
    learningLanguage2: LearningLanguage | LearningLanguageWithTandemWithPartnerProfile
): LearningType => {
    switch (learningLanguage1.learningType) {
        case LearningType.BOTH:
            if (
                (learningLanguage2.learningType === LearningType.BOTH ||
                    learningLanguage2.learningType === LearningType.TANDEM) &&
                learningLanguage1.campus &&
                learningLanguage1.campus.id === learningLanguage2.campus?.id
            ) {
                return LearningType.TANDEM;
            }

            return LearningType.ETANDEM;
        case LearningType.ETANDEM:
            return learningLanguage1.learningType;
        case LearningType.TANDEM:
            return learningLanguage1.learningType;
        default:
            throw new Error(`Unknown LearningType ${learningLanguage1.learningType}`);
    }
};
