/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import React from 'react';
import {
    TopToolbar,
    EditButton,
    ArrayField,
    SingleFieldList,
    ChipField,
    DateField,
    useTranslate,
    SimpleShowLayout,
    TextField,
    Show,
    FunctionField,
    Loading,
} from 'react-admin';
import University from '../../../entities/University';
import useSecurityContext from './useSecurityContext';

const UniversityShowAction = () => (
    <TopToolbar>
        <EditButton />
    </TopToolbar>
);

const UniversityShow = (props: any) => {
    const translate = useTranslate();
    const { isLoading, isUniversityAdmin } = useSecurityContext();

    if (isLoading || isUniversityAdmin) return <Loading />;

    return (
        <Show actions={<UniversityShowAction />} title={translate('universities.label')} {...props}>
            <SimpleShowLayout sx={{ m: 3 }}>
                <TextField label={translate('universities.show.name')} source="name" />
                <TextField label={translate('universities.show.country')} source="country.name" />
                <FunctionField
                    label={translate('universities.show.defaultContact')}
                    render={(record: University) =>
                        record.defaultContact
                            ? `${record.defaultContact.firstname} ${record.defaultContact.lastname}`
                            : translate('universities.show.noDefaultContact')
                    }
                />
                <FunctionField
                    label={translate('universities.show.language')}
                    render={(record: University) => translate(`languages_code.${record.nativeLanguage.code}`)}
                />
                <TextField label={translate('universities.show.timezone')} source="timezone" />
                <DateField label={translate('universities.show.admission_start')} source="admissionStart" />
                <DateField label={translate('universities.show.admission_end')} source="admissionEnd" />
                <DateField label={translate('universities.show.open_service')} source="openServiceDate" />
                <DateField label={translate('universities.show.close_service')} source="closeServiceDate" />
                <TextField label={translate('universities.show.max_tandems_per_user')} source="maxTandemsPerUser" />
                <ArrayField label={translate('universities.show.sites')} sortable={false} source="sites">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <TextField label={translate('universities.show.codes')} source="codes">
                    <SingleFieldList>
                        <ChipField source="id" />
                    </SingleFieldList>
                </TextField>
                <TextField label={translate('universities.show.domains')} source="domains">
                    <SingleFieldList>
                        <ChipField source="id" />
                    </SingleFieldList>
                </TextField>
                <TextField label={translate('universities.show.website')} source="website" />
                <FunctionField
                    label={translate('universities.show.pairingMode')}
                    render={(data: University) =>
                        translate(`universities.pairingModes.${data.pairingMode.toLowerCase()}`)
                    }
                />
                <TextField label={translate('universities.show.id')} source="id" />
                <TextField label={translate('universities.show.notificationEmail')} source="notificationEmail" />
            </SimpleShowLayout>
        </Show>
    );
};

export default UniversityShow;
