/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import { VideoCall } from '@mui/icons-material';
import { Box, Container, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Loading, useDataProvider, useGetIdentity, useRedirect, useTranslate } from 'react-admin';
import { Conversation } from '../../entities/Conversation';
import SocketIoProvider from '../../providers/socketIoProvider';
import ChatInputSender from './ChatInputSender';
import MessagesList from './MessagesList';
import useHandleMessagesFromConversation from './useHandleMessagesFromConversation';

interface ChatContentProps {
    conversation: Conversation;
}

const ChatContent = ({ conversation }: ChatContentProps) => {
    const translate = useTranslate();
    const user = useGetIdentity();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const [socketIoProvider, setSocketIoProvider] = useState<SocketIoProvider | undefined>();

    const { messages, isScrollForwardOver, isScrollBackwardOver, isLoading, loadMessages, addNewMessage } =
        useHandleMessagesFromConversation({
            conversationId: conversation.id,
        });

    useEffect(() => {
        socketIoProvider?.connect();
        socketIoProvider?.onMessage(conversation.id, addNewMessage);

        return () => {
            socketIoProvider?.disconnect();
            socketIoProvider?.offMessage();
        };
    }, [conversation.id, socketIoProvider]);

    useEffect(() => {
        const loadSocketIoProvider = async () => {
            setSocketIoProvider(await dataProvider.getSocketIoProvider());
        };

        loadSocketIoProvider();
    }, []);

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '500px',
                height: '100%',
                marginTop: '56px',
                overflow: 'hidden',
                padding: ['0', '0', '0'],
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    lineHeight: 3,
                    paddingLeft: 2,
                    paddingRight: 2,
                    borderBottom: '1px solid #E0E0E0',
                }}
            >
                <Typography variant="h6">
                    {translate('chat.show.title', {
                        partner: `${conversation?.partner?.lastname} ${conversation?.partner?.firstname}`,
                    })}
                </Typography>
                <IconButton onClick={() => redirect(`/jitsi?roomName=${conversation.id}`)}>
                    <VideoCall />
                </IconButton>
            </Box>

            {isLoading ? (
                <Loading />
            ) : (
                <MessagesList
                    currentMessageSearchId={undefined}
                    isScrollBackwardOver={isScrollBackwardOver}
                    isScrollForwardOver={isScrollForwardOver}
                    loadMessages={(direction) => loadMessages({ isFirstMessage: false, direction })}
                    messages={messages}
                    userId={user.identity?.id as string}
                />
            )}

            <Box sx={{ display: 'flex', flexDirection: 'column', borderTop: '1px solid #E0E0E0' }}>
                <ChatInputSender
                    conversation={conversation}
                    profile={user.identity as UserIdentity}
                    socketIoProvider={socketIoProvider}
                />
            </Box>
        </Container>
    );
};

export default ChatContent;
