/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React from 'react';
import { useCreatePath, useTranslate } from 'react-admin';
import { Link, useLocation } from 'react-router-dom';
import { LinkPage } from '../menu/CustomMenu';
import useCurrentPathname from '../menu/useCurrentPathname';

type LinkTabsProps = {
    links: LinkPage[];
};

function samePageLinkNavigation(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }

    return true;
}

const getLinkKey = (link: LinkPage): string => `${link.resource}-${link.type}`;

const LinkTabs = ({ links }: LinkTabsProps) => {
    const translate = useTranslate();
    const location = useLocation();
    const createPath = useCreatePath();
    const currentPathname = useCurrentPathname();
    const [value, setValue] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        const matchingLinks = links.filter((link) => currentPathname.startsWith(link.resource));
        const currentLink = matchingLinks.sort((a, b) => b.resource.length - a.resource.length)[0];
        if (currentLink) {
            setValue(getLinkKey(currentLink));
        }
        if (!currentLink) {
            const firstPartPathname = location.pathname.split('/')[1]; // Pathname is split because of subpages
            const currentTab = links.filter((link) => link.resource.startsWith(firstPartPathname))[0];
            if (currentTab) {
                setValue(getLinkKey(currentTab));
            }
        }
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if (
            event.type !== 'click' ||
            (event.type === 'click' && samePageLinkNavigation(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>))
        ) {
            setValue(newValue);
        }
    };

    return (
        <Box>
            {value !== undefined ? (
                <TabContext value={value}>
                    <TabList component="div" onChange={handleChange}>
                        {links.map((link) => (
                            <Tab
                                key={getLinkKey(link)}
                                component={Link}
                                label={translate(link.label)}
                                to={createPath(link)}
                                value={getLinkKey(link)}
                            />
                        ))}
                    </TabList>
                </TabContext>
            ) : null}
        </Box>
    );
};

export default LinkTabs;
